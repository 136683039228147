import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { db } from "../../../../Firebase/Firebase";
import { ref, push, child, get } from "firebase/database";
import { useSnackbar } from "notistack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const StyledRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  background: "#000",
  minHeight: "100vh",
  color: "#fff",
}));
const initialValues = {
  name: "",
  hand: "",
  buy_in: "",
  max_players: "",
  owner_phone: "",
  status: "",
};

const ClubData = () => {
  // const {id} = useParams()
  const { state } = useLocation();
  // console.log(state, "This is ")
  const [formValues, setFormValues] = useState(initialValues);
  const [clubData, setClubData] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnakcbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [age, setAge] = React.useState(10);

  const handleChangeM = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    if (clubData === null || typeof clubData === "undefined") {
      setLoading(true);
      // setnewArray(Object.values(clubData))
    } else {
      setLoading(false);
    }
  }, [clubData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const clubDataRef = ref(db, 'clubData/' + state.id);
  //     const newTable = {
  //         name: formValues.name,
  //         hand: formValues.hand,
  //         buy_in: formValues.buy_in,
  //         max_players: formValues.max_players,
  //         owner_phone: formValues.owner_phone,
  //         status:age
  //     };
  //     push(child(clubDataRef, 'tableData'), newTable)
  //         .then(() => {
  //             setFormValues(initialValues);
  //             setOpen(false)
  //             fetchClubData();
  //             enqueueSnakcbar("Added Successfully", {
  //                 variant:'success'
  //             })
  //         })
  //         .catch((error) => {
  //             console.error('Error adding table:', error.message);
  //         });
  // };
  const fetchClubData = async () => {
    try {
      const clubDataRef = ref(db, "clubData/" + state.id);
      const snapshot = await get(clubDataRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        setClubData(data);
      } else {
        console.error("Club data not found");
      }
    } catch (error) {
      console.error("Error fetching club data:", error.message);
    }
  };
  useEffect(() => {
    fetchClubData();
  }, []);
  // console.log(clubData)
  return (
    <StyledRoot>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" fontWeight="bold">
          {state.NAME}
        </Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add Table
        </Button>
      </Box>
      <Divider sx={{ background: "#fff", mt: 2 }} />
      <Box mt={2}>
        <Typography variant="h4" fontWeight="bold">
          {state.NAME} tables
        </Typography>
      </Box>
      <Grid container sx={{ mt: 2 }} spacing={2}>
        {loading ? (
          <Typography variant="h5" textAlign="center">
            Please Wait
          </Typography>
        ) : Object.values(clubData.tableData).length < 2 ? (
          <Box sx={{ ml: "42%", mt: 5 }}>
            <Typography variant="h4" fontWeight="bold">
              {" "}
              No tables found.. ☹️
            </Typography>
          </Box>
        ) : (
          Object.values(clubData.tableData)
            .slice(1)
            .map((val) => {
              console.log(val);
              return (
                <Grid item xs={12} md={4} lg={4}>
                  <Card>
                    <CardContent>
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Table Name
                          </Typography>
                          <Typography>{val.name}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Hand
                          </Typography>
                          <Typography>{val.hand}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Buy in
                          </Typography>
                          <Typography>{val.buy_in}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Max Players
                          </Typography>
                          <Typography>{val.max_players}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Owner Phone
                          </Typography>
                          <Typography>{val.owner_phone}</Typography>
                        </Box>
                      </Stack>
                      <Divider sx={{ mt: 1 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Button variant="outlined">Edit</Button>
                        <Button variant="contained" sx={{ ml: 1 }}>
                          Delete
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
        )}
      </Grid>
      {/* <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add table in {state.NAME}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="name"
                  label="Name OF Table"
                  value={formValues.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="hand"
                  label="Hand"
                  value={formValues.hand}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="buy_in"
                  label="Buy In"
                  value={formValues.buy_in}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="max_players"
                  label="Max Players"
                  value={formValues.max_players}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="owner_phone"
                  label="Owner Phone No"
                  value={formValues.owner_phone}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Status"
                    onChange={handleChangeM}
                  >
                    <MenuItem value={10}>On</MenuItem>
                    <MenuItem value={20}>OFF</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Add Table
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </StyledRoot>
  );
};

export default ClubData;

const initialState = {
  isAuthenticated: false,
  token: localStorage.getItem("token"),
  id: null,
  notifications_invisibility: true,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS": {
      localStorage.setItem("token", action.payload.accessToken);
      // console.log('LOGIN ACTION', action)
      return {
        ...state,
        // ...action.payload.data,
        token: action.payload.accessToken,
        isAuthenticated: true,
        id: action.payload.uid,
      };
    }
    case "LOGOUT_SUCCESS": {
      localStorage.removeItem("token");
      // console.log('LOGIN ACTION', action)
      return {
        token: null,
        isAuthenticated: false,
        id: null,
      };
    }
    case "NOTIFICATIONS_INVISIBILITY": {
      // console.log('LOGIN ACTION', action)
      return {
        ...state,
        notifications_invisibility: action.payload,
      };
    }

    default:
      return state;
  }
};

export default adminReducer;

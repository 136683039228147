import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
  Stack,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import AddReward from "./components/AddRewards";
import EditReward from "./components/EditRewards";
import { useSnackbar } from "notistack";
import { db } from "../../../Firebase/Firebase";
import { ref, remove, get } from "firebase/database";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { RotatingLines } from "react-loader-spinner";
import { getStorage, deleteObject, ref as storageRef } from "firebase/storage";

const StyledPackage = styled(Card)(({ theme }) => ({
  background: "#999",
  border: "1px solid rgba(0,0,0,0.5)",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // padding: theme.spacing(2),
}));

const Rewards = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const theme = useTheme();

  const [openEdit, setOpenEdit] = useState(false);
  const [editRewardData, setEditRewardData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getRewardData = async () => {
    try {
      const rewardsData = ref(db, "Rewards");
      const snapshot = await get(rewardsData);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setData(values);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditRewardClose = () => {
    setOpenEdit(false);
  };

  const handleEditReward = (rewardData) => {
    setOpenEdit(true);
    setEditRewardData(rewardData);
  };

  React.useEffect(() => {
    getRewardData();
  }, []);

  const handleDelete = async (reward) => {
    const index2F = reward.image.indexOf("2F");
    const indexQuestionMark = reward.image.indexOf("?");
    const substring = reward.image.substring(index2F + 2, indexQuestionMark);

    try {
      const storage = getStorage();
      const desertRef = storageRef(storage, `images/${substring}`);
      const rewardDataRef = ref(db, `Rewards/${reward.id}`);
      await deleteObject(desertRef);
      await remove(rewardDataRef);
      enqueueSnackbar("Rewards Deleted Successfully", {
        variant: "success",
      });
      getRewardData();
    } catch (error) {
      console.error("Error in deleting reward:", error);
    }
  };

  console.log("data======", data);

  return (
    <Stack sx={{ gap: { xs: 3, md: 5 }, padding: theme.spacing(8) }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          py: { xs: 3, lg: 2 },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: "15px", md: "18px", lg: "22px" } }}
        >
          Rewards
        </Typography>
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={() => setOpen(true)}
        >
          Add Reward
        </Button>
      </Box>
      <Grid container spacing={{ xs: 2, sm: 3, md: 3.2 }}>
        {data.length > 0 ? (
          data.map((val, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <StyledPackage>
                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Tooltip title="Edit" placement="top">
                      <Button onClick={() => handleEditReward(val)}>
                        <EditIcon style={{ color: "#DE3163" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button onClick={() => handleDelete(val)}>
                        <DeleteIcon style={{ color: "#ff0000" }} />
                      </Button>
                    </Tooltip>
                  </Stack>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="treasure"
                        src={val.image}
                        style={{ height: "230px" }}
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {val.reward_chip}
                    </Typography>
                  </CardContent>
                </StyledPackage>
              </Grid>
            );
          })
        ) : (
          <RotatingLines
            strokeColor="#6f6e86"
            strokeWidth="5"
            animationDuration="0.75"
            width="160"
            color="#6f6e86"
          />
        )}
      </Grid>
      <AddReward open={open} close={handleClose} success={getRewardData} />
      <EditReward
        open={openEdit}
        close={handleEditRewardClose}
        data={editRewardData}
        success={getRewardData}
      />
    </Stack>
  );
};

export default Rewards;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Button,
  DialogActions,
  Stack,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { db, imageDb } from "../../../../Firebase/Firebase";
import { ref, update } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { uid } from "uid";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";

const initialValues = {
  id: "",
  image: "",
  reward_chip: "",
};
const EditReward = ({ open, close, data, success }) => {
  // console.log("data======", data);
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = async (event) => {
    const index2F = formValues.image.indexOf("2F");
    const indexQuestionMark = formValues.image.indexOf("?");
    const substring = formValues.image.substring(
      index2F + 2,
      indexQuestionMark
    );
    console.log(substring);

    const selectedFile = event.target.files[0];
    try {
      setImageLoading(true);
      const storage = getStorage();
      const desertRef = storageRef(storage, `images/${substring}`);
      await deleteObject(desertRef);

      // Upload the image file to Firebase Storage
      const uploadStorageRef = storageRef(
        imageDb,
        `images/${selectedFile.name}-${uid()}`
      );
      await uploadBytes(uploadStorageRef, selectedFile);

      await getDownloadURL(uploadStorageRef)
        .then((url) => {
          setFormValues((previous) => ({
            ...previous,
            image: url,
          }));
          setImageLoading(false);
          console.log("download URL", url);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const rewardsRef = ref(db, `Rewards/${formValues.id}`);
      const updatedData = { ...formValues };
      await update(rewardsRef, updatedData);
      close();
      setFormValues(initialValues);
      enqueueSnackbar("Data Updated Successfully", {
        variant: "success",
      });
      success();
    } catch (error) {
      console.error("Error in updating reward data:", error);
    }
  };

  console.log("===formValues=====", formValues);

  useEffect(() => {
    setFormValues({
      ...data,
    });
  }, [data]);

  return (
    <div>
      <Dialog open={open} onClose={close} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Rewards</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                {imageLoading ? (
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "230px",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#6f6e86"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="100"
                      color="#6f6e86"
                      visible={imageLoading}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <img
                      src={formValues.image}
                      alt="url"
                      style={{
                        width: "100%",
                        height: "230px",
                        objectFit: "cover",
                      }}
                    />
                    <Stack sx={{ alignItems: "center", mt: 2 }}>
                      <input
                        type="file"
                        id="imageInput"
                        name="image"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Update Image
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  label="Rewards Amount "
                  placeholder="Rewards Amount "
                  fullWidth
                  name="reward_chip"
                  value={formValues.reward_chip}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              {loading ? (
                <RotatingLines
                  strokeColor="#6f6e86"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible={loading}
                />
              ) : (
                "Update"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EditReward;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { set, ref, push } from "firebase/database";
import { db } from "../../../../Firebase/Firebase";
import { useSnackbar } from "notistack";
const CreateClub = ({ open, close, data }) => {
  const [name, setName] = useState("");
  const tables = [""];
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      Name: name,
      // tableData: tables
    };
    try {
      const pkgDataRef = ref(db, "clubData");
      const newPkgDataRef = push(pkgDataRef);
      const packageId = newPkgDataRef.key;
      const updatedFormValues = { ...body, id: packageId };
      await set(newPkgDataRef, updatedFormValues);
      close();
      data();
      enqueueSnackbar("Data Added Successfully", {
        variant: "success",
      });
      setName("");
    } catch (error) {
      console.error("Error adding package data:", error);
    }
  };
  return (
    <Dialog open={open} onClose={close} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Create Club</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            label="Club Name"
            fullWidth
            name="name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" type="submit">
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateClub;

import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
  Stack,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import AddTournament from "./components/AddTournament";
import EditTournament from "./components/EditTournament";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";

import { db } from "../../../Firebase/Firebase";
import { uid } from "uid";
import { ref, remove, get } from "firebase/database";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledPackage = styled(Card)(({ theme }) => ({
  background: "#999",
  border: "1px solid rgba(0,0,0,0.5)",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-center",
  // padding: theme.spacing(2),
}));

const Tournament = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const theme = useTheme();
  //  open = { open };
  //  close = { handleClose };
  //  success = { getTournamentsData };
  const [openEdit, setOpenEdit] = useState(false);
  const [editTournamentData, setEditTournamentData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getTournamentsData = async () => {
    try {
      const tournamentsRef = ref(db, "Tournaments");
      const snapshot = await get(tournamentsRef);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setData(values);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditTournamentClose = () => {
    setOpenEdit(false);
  };

  const handleEditTournament = (id) => {
    const tournament = data.find((tournament) => tournament.id === id);
    setEditTournamentData(tournament);
    setOpenEdit(true);
  };

  React.useEffect(() => {
    getTournamentsData();
  }, []);

  const handleDeleteTournament = async (id) => {
    try {
      const tournamentRef = ref(db, `Tournaments/${id}`);
      await remove(tournamentRef);
      console.log("Tournament is deleted successfully..");
      // Update the state to reflect the changes
      getTournamentsData();
      enqueueSnackbar("Tournament Deleted Successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting Tournament:", error);
    }
  };

  return (
    <Stack sx={{ gap: { xs: 3, md: 5 }, padding: theme.spacing(8) }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          py: { xs: 3, lg: 2 },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: "13px", sm: "15px", md: "18px", lg: "22px" } }}
        >
          Tournaments
        </Typography>
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={() => setOpen(true)}
          sx={{ fontSize: { xs: "10px", sm: "13px", md: "15px", lg: "18px" } }}
        >
          Add Tournament
        </Button>
      </Box>
      <Grid container spacing={{ xs: 2, sm: 3, md: 3.2 }}>
        {data.length > 0 ? (
          data.map((val) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <StyledPackage>
                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Tooltip title="Edit" placement="top">
                      <Button onClick={() => handleEditTournament(val.id)}>
                        <EditIcon style={{ color: "#DE3163" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button onClick={() => handleDeleteTournament(val.id)}>
                        <DeleteIcon style={{ color: "#ff0000" }} />
                      </Button>
                    </Tooltip>
                  </Stack>
                  <CardContent>
                    <Stack
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignSelf: "center",
                        }}
                      >
                        <img
                          alt="treasure"
                          src={val.image}
                          style={{ height: "190px" }}
                        />
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          mt: 1,
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: {
                            xs: "13px",
                            sm: "15px",
                            md: "18px",
                            lg: "22px",
                          },
                        }}
                      >
                        {val.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            background:
                              "linear-gradient(to right, #5D5B7C, #302f54)",
                            height: "20px",
                            width: "130px",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                            p: 2,
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="coin"
                            src="/assets/images/coin.png"
                            style={{ height: "30px" }}
                          />
                          <Typography
                            sx={{ ml: 1 }}
                            variant="h6"
                            fontWeight="bold"
                          >
                            {val.amount}
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </CardContent>
                </StyledPackage>
              </Grid>
            );
          })
        ) : (
          <RotatingLines
            strokeColor="#6f6e86"
            strokeWidth="5"
            animationDuration="0.75"
            width="160"
            color="#6f6e86"
          />
        )}
      </Grid>
      <AddTournament
        open={open}
        close={handleClose}
        success={getTournamentsData}
      />
      <EditTournament
        open={openEdit}
        close={handleEditTournamentClose}
        data={editTournamentData}
        success={getTournamentsData}
      />
    </Stack>
  );
};

export default Tournament;

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router";

const Players = () => {
  const { state } = useLocation();
  console.log(state.Players, "PPPPPPPPPPPPPP");
  return (
    <Box
      sx={{
        height: "100vh",
        background: "#000",
        color: "#fff",
        pt: 4,
      }}
    >
      <Box sx={{ px: 3 }}>
        <Typography variant="h3" textAlign="center" sx={{ mb: 2 }}>
          {" "}
          Players
        </Typography>
        <Table>
          <TableHead sx={{ background: "#e2e2e2" }}>
            <TableRow>
               <TableCell>Player Name</TableCell>
              <TableCell>Tip Percentage</TableCell>
              <TableCell>Tips</TableCell>
              <TableCell>Profit</TableCell>
              <TableCell>Date Started</TableCell>
              <TableCell>Date Ended</TableCell>
              <TableCell>Big Blind</TableCell>
              <TableCell>Buy In</TableCell>
              <TableCell>Game Type</TableCell>
              <TableCell>Hands</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(state?.Players)?.map((val, ind) => {
              return (
                <TableRow>
                  
                  <TableCell sx={{ color: "#fff" }}>
                    {val?.PlayerName}
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.TipPercentage}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{Number(val?.Tips).toFixed(0)}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{Number(val?.Profit).toFixed(0)}</TableCell>

                  <TableCell sx={{ color: "#fff" }}>{val?.DateStarted}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.DateEnded}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.BigBlind}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.BuyIn}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.GameType}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{val?.Hands}</TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Players;

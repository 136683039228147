import {
  AppBar,
  Toolbar,
  styled,
  Typography,
  Stack,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Container,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useRef } from "react";
import { bgBlur } from "./../../../../../utils/cssStyles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useResponsive from "../../../../../components/hooks/useResponsive";
import { Link } from "react-router-dom";
import {
  logOut,
  notificationsInvisibility,
} from "../../../../../store/actions/adminActions";
const NAV_WIDTH = 280;

const HEADER_MOBILE = 54;

const HEADER_DESKTOP = 62;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({color: theme.palette.primary.main}),
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    display: "flex",
    justifyContent: "space-between",
  },
}));
const TopBar = ({ onOpenNav, toggleMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notifications_invisibility = useSelector(
    (state) => state.admin.notifications_invisibility
  );

  // console.log("notifications_invisibility", notifications_invisibility);
  const open = Boolean(anchorEl);
  const [openNotifications, setOpenNotifications] = useState(false);
  const notificationsRef = useRef(null);
  const notifications = [];

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationsOpen = () => {
    dispatch(notificationsInvisibility(true));
    navigate("/notifications");
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };
  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };
  return (
    <div>
      <StyledRoot>
        <StyledToolbar>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                gap: { xs: 0.5, sm: 1.3, md: 2.4 },
              }}
            >
              {isDesktop ? (
                <Box />
              ) : (
                <IconButton onClick={toggleMenu}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: 800,
                  fontSize: { xs: "13px", sm: "15px", md: "18px" },
                }}
              >
                {location.pathname == "/admin/dashboard"
                  ? "Dashboard"
                  : location.pathname == "/admin/leaderboard"
                  ? "LeaderBoard"
                  : location.pathname == "/admin/order"
                  ? "Orders"
                  : "Dashboard"}
              </Typography>
            </Stack>
            <Box>
              {/* <FormControl sx={{ m: 1, width:'400px'}} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password"> Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          startAdornment={
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
          }
          label="Search"
          size='small'
          placeholder='Search here.....'
          sx={{borderRadius:'10px', background:'#f7f7f7'}}
        />
      </FormControl>  */}
            </Box>
            <Box />

            {/* <Stack
        direction="row"
        alignItems="center"
        spacing={{
          xs:0.5,
          sm:1
        }}
        >
          <IconButton
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
          >
            <Badge badgeContent={102}  color="primary">
            <NotificationsNoneIcon sx={{color:'#bc9000', fontSize:'2rem'}} /> 
            </Badge>
          </IconButton>
          <Box sx={{display:'flex', alignItems:'center'}}>
            <Avatar src="/assets/images/admin.png" sx={{cursor:'pointer',}} onClick={handleAvatarClick}/>
            <Box>
            <Typography sx={{color:'#000',mb:-0.5}}> Mudasser</Typography>
            <Typography sx={{color:'#000', fontSize:'12px', fontWeight:'bold'}}>Admin </Typography>
            </Box>
            <IconButton>
                <ArrowDropDownIcon />
            </IconButton>
          </Box>
        </Stack> */}
            <Stack direction="row" sx={{ gap: 1 }}>
              <IconButton onClick={handleNotificationsOpen}>
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={notifications_invisibility}
                >
                  <NotificationsIcon
                    sx={{
                      color: "#e2e2e2",
                      fontSize: {
                        xs: "15px",
                        md: "18px",
                        lg: "22px",
                      },
                    }}
                  />
                </Badge>
              </IconButton>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "15px",

                    textTransform: "none",
                  },
                }}
                onClick={handleLogout}
              >
                Log Out
              </Button>
            </Stack>
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </div>
  );
};

export default TopBar;

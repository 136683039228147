import React from "react";
import { useSelector } from "react-redux";

const AdminDash = () => {
  const user = useSelector((state) => state.admin);
  // console.log(user);
  return <div>Admin Dashb</div>;
};

export default AdminDash;

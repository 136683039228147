import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  Typography,
  styled,
  Stack,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import AddPackage from "./components/AddPackage";
import EditPackage from "./components/EditPackage";
import { useSnackbar } from "notistack";

import { db } from "../../../Firebase/Firebase";
import { ref, remove, get } from "firebase/database";
import { RotatingLines } from "react-loader-spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getStorage, deleteObject, ref as storageRef } from "firebase/storage";

const StyledPackage = styled(Card)(({ theme }) => ({
  background: "#999",
  border: "1px solid rgba(0,0,0,0.5)",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));
const Ribbon = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 20,
  left: 0,
  background: "red",
  color: "#fff",
  padding: theme.spacing(1),
  transform: "rotate(315deg)",
}));
const Packages = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const theme = useTheme();

  const [openEdit, setOpenEdit] = useState(false);
  const [editPkgData, setEditPkgData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getPkgData = async () => {
    try {
      const pkgDataRef = ref(db, "pkgData");
      const snapshot = await get(pkgDataRef);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setData(values);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditPkgClose = () => {
    setOpenEdit(false);
  };

  const handleEditPkg = (id) => {
    const pkgData = data.find((pkg) => pkg.id === id);
    setEditPkgData(pkgData);
    setOpenEdit(true);
  };

  React.useEffect(() => {
    getPkgData();
  }, []);

  const handleDelete = async (packageData) => {
    console.log("in handleDelete function=====");
    try {
      const index2F = packageData.image.indexOf("2F");
      const indexQuestionMark = packageData.image.indexOf("?");
      const substring = packageData.image.substring(
        index2F + 2,
        indexQuestionMark
      );

      const storage = getStorage();
      const desertRef = storageRef(storage, `images/${substring}`);
      await deleteObject(desertRef);
      const pkgDataRef = ref(db, `pkgData/${packageData.id}`);
      await remove(pkgDataRef);
      enqueueSnackbar("Tournament Deleted Successfully", {
        variant: "success",
      });
      getPkgData();
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };
  return (
    <Stack sx={{ gap: { xs: 3, md: 5 }, padding: theme.spacing(8) }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          py: { xs: 3, lg: 2 },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: "14px", sm: "15px", md: "18px", lg: "22px" } }}
        >
          Packages
        </Typography>
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={() => setOpen(true)}
          sx={{ fontSize: { xs: "13px", sm: "15px", md: "18px" } }}
        >
          Add Package
        </Button>
      </Box>
      <Grid container spacing={{ xs: 2, sm: 3, md: 3.2 }}>
        {data.length > 0 ? (
          data.map((val) => {
            return (
              <Grid key={val.id} item xs={12} sm={6} md={4}>
                <StyledPackage>
                  <Ribbon>{val.discount_per}% OFF</Ribbon>

                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Tooltip title="Edit" placement="top">
                      <Button onClick={() => handleEditPkg(val.id)}>
                        <EditIcon style={{ color: "#DE3163" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button onClick={() => handleDelete(val)}>
                        <DeleteIcon style={{ color: "#ff0000" }} />
                      </Button>
                    </Tooltip>
                  </Stack>
                  <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img
                        alt="treasure"
                        src={val.image}
                        style={{ height: "190px" }}
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {val.chips_quantity} chips
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          background:
                            "linear-gradient(to right, #5D5B7C, #302f54)",
                          height: "20px",
                          width: "130px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
                          p: 2,
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="coin"
                          src={"/assets/images/treasure.png"}
                          style={{ height: "30px" }}
                        />
                        <Typography
                          sx={{ ml: 1 }}
                          variant="h6"
                          fontWeight="bold"
                        >
                          {val.discounted_amount}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </StyledPackage>
              </Grid>
            );
          })
        ) : (
          <RotatingLines
            strokeColor="#6f6e86"
            strokeWidth="5"
            animationDuration="0.75"
            width="160"
            color="#6f6e86"
          />
        )}
      </Grid>
      <AddPackage open={open} close={handleClose} success={getPkgData} />
      <EditPackage
        open={openEdit}
        close={handleEditPkgClose}
        data={editPkgData}
        success={getPkgData}
      />
    </Stack>
  );
};

export default Packages;

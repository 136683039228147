import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Button,
  Typography,
  DialogActions,
  Stack,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { db, imageDb } from "../../../../Firebase/Firebase";
import { set, ref, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { uid } from "uid";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";

const initialValues = {
  image: "",
  reward_chip: "",
};
const AddReward = ({ open, close, success }) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    try {
      // Upload the image file to Firebase Storage
      setImageLoading(true);
      const uploadStorageRef = storageRef(
        imageDb,
        `images/${selectedFile.name}-${uid()}`
      );
      await uploadBytes(uploadStorageRef, selectedFile);

      await getDownloadURL(uploadStorageRef)
        .then((url) => {
          setFormValues((previous) => ({
            ...previous,
            image: url,
          }));
          setImageLoading(false);
          console.log("download URL", url);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const rewardsRef = ref(db, "Rewards");
      const newRewardsRef = push(rewardsRef);
      const rewardId = newRewardsRef.key;
      const updatedFormValues = { ...formValues, id: rewardId };
      await set(newRewardsRef, updatedFormValues);
      close();
      setFormValues(initialValues);
      enqueueSnackbar("Data Added Successfully", {
        variant: "success",
      });
      success();
    } catch (error) {
      console.error("Error adding reward data:", error);
    }
  };

  // console.log("===formValues=====", formValues);

  return (
    <div>
      <Dialog open={open} onClose={close} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add New Rewards</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              {imageLoading ? (
                <Grid item xs={12} lg={12}>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "230px",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#6f6e86"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="100"
                      color="#6f6e86"
                      visible={imageLoading}
                    />
                  </Stack>
                </Grid>
              ) : formValues.image ? (
                <Grid item xs={12} lg={12}>
                  <img
                    src={formValues.image}
                    alt="url"
                    style={{
                      width: "100%",
                      height: "230px",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} lg={12}>
                  <Typography variant="h6" fontWeight="bold" mb={1}>
                    Choose Image
                  </Typography>
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px dashed grey",
                      padding: "10px",
                    }}
                  >
                    <input
                      type="file"
                      id="imageInput"
                      name="image"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Choose File
                    </Button>
                    <p style={{ marginTop: "10px" }}>
                      {formValues.image ? formValues.image : "No File Chosen"}
                    </p>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} lg={12}>
                <TextField
                  label="Rewards Amount "
                  placeholder="Rewards Amount "
                  fullWidth
                  name="reward_chip"
                  value={formValues.reward_chip}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              {loading ? (
                <RotatingLines
                  strokeColor="#6f6e86"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible={loading}
                />
              ) : (
                "Add"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddReward;

import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Box } from "@mui/material";
import { ref, get } from "firebase/database";
import { db } from "../../../Firebase/Firebase";
import TableCard from "./components/TableCard";

const Tables = () => {
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTablesData = async () => {
    try {
      const tablesRef = ref(db, "Tables");
      const snapshot = await get(tablesRef);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setTables(values);
      } else {
        console.log("No tables data available");
      }
    } catch (error) {
      console.error("Error fetching tables:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTablesData();
  }, []);

  return (
    <Box sx={{ background: "#000", height: "100vh", p: 5 }}>
      <Grid container spacing={3}>
        {loading ? (
          <CircularProgress />
        ) : (
          tables.map((table) => (
            <Grid item xs={12} sm={6} md={4} key={table.id}>
              <TableCard tableData={table} />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default Tables;

import { Box, Typography } from "@mui/material";
import React from "react";

const Membership = ({ data }) => {
  //   console.log(Object.keys(data));
  const newData = data != null && Object.keys(data);
  //   console.log(newData);
  return (
    <Box>
      <Box>
        {newData == false
          ? "No Data"
          : newData?.map((val, ind) => {
              return (
                <Typography sx={{ mb: 0.5 }}>
                  {ind + 1 + " "}:{val}
                </Typography>
              );
            })}
      </Box>
    </Box>
  );
};

export default Membership;

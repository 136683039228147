import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import React from "react";
const StyledCell = styled(TableCell)(({ theme }) => ({
  color: "#fff",
  flex: 1,
}));
const T_D_LIST = ({ data }) => {
  console.log(data);
  return (
    <Box>
      <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledCell>Name</StyledCell>
              <StyledCell>Owner Phone</StyledCell>
              <StyledCell>Max Players</StyledCell>
              <StyledCell>Hand</StyledCell>
              <StyledCell>Game Type</StyledCell>
              <StyledCell>Order</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((val, ind) => {
              return (
                <TableRow key={ind}>
                  <StyledCell>{val.name || "----"}</StyledCell>
                  <StyledCell>{val.owner_phone || "----"}</StyledCell>
                  <StyledCell>{val.max_players || "----"}</StyledCell>
                  <StyledCell>{val.hand || "----"}</StyledCell>
                  <StyledCell>{val.gameType || "----"}</StyledCell>
                  <StyledCell>{val.order || "----"}</StyledCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default T_D_LIST;

import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
          main: '#5D5B7C',
        },
        secondary: {
          main: '#dbb87d',
        },
      },
    
});
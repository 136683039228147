import React from "react";
import { Card, Typography, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const TableCard = ({ tableData }) => {
  const navigate = useNavigate()
  const handleClickCard = () => {
    navigate("/admin/tables/players", {state:tableData})
  };
  return (
    <Card sx={{ cursor: "pointer" }} onClick={handleClickCard}>
      <Box padding={2}>
        <Typography variant="h6" fontWeight="bold">
          {tableData.RoomName}
        </Typography>
        <Typography variant="body1">Club Code: {tableData.ClubCode}</Typography>
      </Box>
    </Card>
  );
};

export default TableCard;

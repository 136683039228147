export const adminLogin = (user) => (dispatch) => {
  dispatch({
    type: "LOGIN_SUCCESS",
    payload: user,
  });
};
export const logOut = () => (dispatch) => {
  dispatch({
    type: "LOGOUT_SUCCESS",
  });
};

export const notificationsInvisibility = (payload) => (dispatch) => {
  dispatch({ type: "NOTIFICATIONS_INVISIBILITY", payload });
};

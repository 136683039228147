import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Button,
  DialogActions,
  Stack,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { db, imageDb } from "../../../../Firebase/Firebase";
import { ref, update } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { uid } from "uid";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";
const inititalValues = {
  id: "",
  name: "",
  price: 0,
  buy_in_amount: 0,
  max_amount: 0,
  min_amount: 0,
  start_time: "",
  players_strength: 1,
};
const EditTournament = ({ open, close, data, success }) => {
  //   console.log("data", data);
  const [formValues, setFormValues] = useState(inititalValues);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = async (event) => {
    const index2F = formValues.image.indexOf("2F");
    const indexQuestionMark = formValues.image.indexOf("?");
    const substring = formValues.image.substring(
      index2F + 2,
      indexQuestionMark
    );
    console.log(substring);

    const selectedFile = event.target.files[0];
    try {
      setImageLoading(true);
      const storage = getStorage();
      const desertRef = storageRef(storage, `images/${substring}`);
      await deleteObject(desertRef);

      // Upload the image file to Firebase Storage
      const uploadStorageRef = storageRef(
        imageDb,
        `images/${selectedFile.name}-${uid()}`
      );
      await uploadBytes(uploadStorageRef, selectedFile);

      await getDownloadURL(uploadStorageRef)
        .then((url) => {
          setFormValues((previous) => ({
            ...previous,
            image: url,
          }));
          setImageLoading(false);
          console.log("download URL", url);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const tournamentsRef = ref(db, `Tournaments/${formValues.id}`);
      const updatedData = { ...formValues };
      await update(tournamentsRef, updatedData);
      close();
      setFormValues(inititalValues);
      enqueueSnackbar("Data Added Successfully", {
        variant: "success",
      });
      success();
    } catch (error) {
      console.error("Error adding package data:", error);
    }
  };

  useEffect(() => {
    if (data) {
      // Check if data is not null or undefined
      setFormValues(data);
    }
  }, [data]);

  //   console.log(formValues);
  return (
    <div>
      <Dialog open={open} onClose={close} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edit Tournament</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                {imageLoading ? (
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "230px",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#6f6e86"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="100"
                      color="#6f6e86"
                      visible={imageLoading}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <img
                      src={formValues.image}
                      alt="url"
                      style={{
                        width: "100%",
                        height: "230px",
                        objectFit: "cover",
                      }}
                    />
                    <Stack sx={{ alignItems: "center", mt: 2 }}>
                      <input
                        type="file"
                        id="imageInput"
                        name="image"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Update Image
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Name"
                  placeholder="Enter Package Name"
                  fullWidth
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Price"
                  placeholder="Enter Price"
                  fullWidth
                  name="price"
                  value={formValues.price}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Buy In Amount"
                  placeholder="Enter Buy In Amount"
                  fullWidth
                  name="buy_in_amount"
                  value={formValues.buy_in_amount}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Maximum Amount"
                  placeholder="Enter Maximum Amount"
                  fullWidth
                  name="max_amount"
                  value={formValues.max_amount}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Minimum Amount"
                  placeholder="Enter Minimum Amount"
                  fullWidth
                  name="min_amount"
                  value={formValues.min_amount}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Start Time"
                  placeholder="Enter Start Time"
                  fullWidth
                  type="time"
                  name="start_time"
                  value={formValues.start_time}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Players Strength"
                  placeholder="Enter Players Strength"
                  fullWidth
                  name="players_strength"
                  value={formValues.players_strength}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            {loading ? (
              <RotatingLines
                strokeColor="#6f6e86"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={loading}
              />
            ) : (
              <Button variant="contained" type="submit">
                Update
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EditTournament;

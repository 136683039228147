import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateClub from "./components/CreateClub";
import { ref, remove, get } from "firebase/database";
import { db } from "../../../Firebase/Firebase";
import { useNavigate } from "react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  background: "#000",
  height: "100vh",
  color: "#fff",
}));

const Clubs = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedClubId, setSelectedClubId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const getClubData = async () => {
    try {
      const clubDataRef = ref(db, "clubData");
      const snapshot = await get(clubDataRef);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        setData(values);
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getClubData();
  }, []);

  const handleClubClick = (val) => {
    navigate(`/admin/club/${val.id}`, { state: val });
    // console.log(val)
  };

  const handleDelete = async () => {
    try {
      if (selectedClubId) {
        const clubDataRef = ref(db, `clubData/${selectedClubId}`);
        await remove(clubDataRef);
        console.log("Club deleted successfully");
        // Update the state to reflect the changes
        getClubData();
      } else {
        console.error("No club selected for deletion");
      }
      handleCloseMenu();
    } catch (error) {
      console.error("Error deleting club:", error);
    }
  };

  const handleClick = (event, clubId) => {
    setAnchorEl(event.currentTarget);
    setSelectedClubId(clubId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <StyledRoot>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          py: { xs: 3, lg: 2 },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: "15px", md: "18px", lg: "22px" } }}
        >
          Clubs
        </Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Create Club
        </Button>
      </Box>
      <Divider sx={{ background: "#fff", mt: 2 }} />
      <CreateClub open={open} close={() => setOpen(false)} data={getClubData} />
      <Grid container sx={{ mt: 2 }} spacing={2}>
        {data.map((val) => {
          // console.log(val);
          return (
            <Grid item xs={12} md={4} lg={3} key={val.id}>
              <Card
                sx={{ cursor: "pointer" }}
                onClick={() => handleClubClick(val)}
              >
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    fontWeight="bold"
                  >
                    {val.Name}
                  </Typography>
                  <IconButton
                    style={{ marginLeft: "auto" }}
                    onClick={(event) => handleClick(event, val.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </StyledRoot>
  );
};

export default Clubs;

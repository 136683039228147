import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Outlet } from 'react-router-dom/dist'

const NavBarLinks = () => {
  return (
    <div>
      
      <Button variant='contained' color='secondary'
      sx={{'&:hover':{background:'#e2e2e2'}}}
      component={Link}
      to="admin-login"
      >
        Admin
      </Button>
    </div>
  )
}

export default NavBarLinks

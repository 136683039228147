import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAXTkL7I9opqlOV2IK2pz48dDkKUWb2WiM",
  authDomain: "poker-45764.firebaseapp.com",
  databaseURL:
    "https://poker-45764-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "poker-45764",
  storageBucket: "poker-45764.appspot.com",
  messagingSenderId: "588320368564",
  appId: "1:588320368564:web:4d7a10695768293264b4e1",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase();
const imageDb = getStorage(firebaseApp);

export { db, auth, firebaseApp, imageDb };

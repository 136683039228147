import React, { createContext, useState, useContext, useEffect } from "react";
import { ref, get, onValue } from "firebase/database";
import { db } from "../Firebase/Firebase";

const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationsVisibility, setNotificationsVisibility] = useState(true);

  const hideNotifications = () => setNotificationsVisibility(false);
  const showNotifications = () => setNotificationsVisibility(true);

  useEffect(() => {
    const getNotificationsData = async () => {
      try {
        const notificationsRef = ref(db, "Notifications_Alert");
        const snapshot = await get(notificationsRef);

        if (snapshot.exists()) {
          const notifications = snapshot.val();
          // Check if there are unread notifications
          const hasUnreadNotifications = Object.values(notifications).some(
            (notification) => !notification.read
          );
          if (hasUnreadNotifications) {
            hideNotifications();
          }
        } else {
          console.log("No notifications available");
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    const notificationsRef = ref(db, "Notifications_Alert");

    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const notificationsArray = Object.values(data);
        if (
          notifications.length &&
          JSON.stringify(notificationsArray) !== JSON.stringify(notifications)
        ) {
          hideNotifications();
        }
        setNotifications(notificationsArray);
      } else {
        setNotifications([]);
      }
    });

    // Call the function to fetch initial notifications data
    getNotificationsData();

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationsVisibility,
        hideNotifications,
        showNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

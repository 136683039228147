import {
  Box,
  styled,
  Tab,
  Tabs,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router";
import MemberShip from "./ShowDataComps/MemberShip";
import Messages from "./ShowDataComps/Messages/Messages";
import T_D_LIST from "./ShowDataComps/TableDataList/T_D_LIST";

const StyledRoot = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(10),
  padding: theme.spacing(3),
  background: "#000",
  minHeight: "100vh",
  color: "#fff",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  justifyContent: "center",
  "& .MuiTabs-indicator": {
    backgroundColor: "#fff",
  },
  "& .MuiTab-root": {
    fontSize: { xs: "12px", sm: "14px", md: "16px" },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#fff",
  fontSize: { xs: "10px", sm: "13px", md: "15px", lg: "18px" },
  "&.Mui-selected": {
    color: "#fff",
  },
}));

const ShowClubData = () => {
  const { state } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  //   console.log(state, "++++++++++++++");

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Stack
      sx={{
        justifyContent: "start",
        gap: 3,
        px: theme.spacing(3),
        py: theme.spacing(3),
        background: "#000",
        minHeight: "100vh",
        color: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          {state.Name}
        </Typography>
        <Typography variant="h4" fontWeight="bold">
          {state.id}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#5b5959", py: 1, mb: 3 }}>
        <StyledTabs value={tabIndex} onChange={handleTabChange} centered>
          <StyledTab label="Membership" />
          <StyledTab label="Messages" />
          <StyledTab label="Table Data List" />
        </StyledTabs>
      </Box>
      {tabIndex === 0 && (
        <Box>
          <MemberShip data={state.Membership ? state.Membership : null} />
        </Box>
      )}
      {tabIndex === 1 && (
        <Box>
          <Messages
            data={state.Messages ? state.Messages : null}
            n_data={state}
          />
        </Box>
      )}
      {tabIndex === 2 && (
        <Box>
          <T_D_LIST data={state.tableDataList ? state.tableDataList : null} />
        </Box>
      )}
    </Stack>
  );
};

export default ShowClubData;

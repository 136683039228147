import React, { useState, useEffect } from "react";
import {
  Box,
  styled,
  TextField,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { db } from "../../../Firebase/Firebase";
import { ref, onValue, push, set } from "firebase/database";
import { useSelector } from "react-redux";
import moment from "moment";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  background: "linear-gradient(to bottom, #000, #008080)",
  overflowX: "hidden",
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: 5,
  overflowY: "auto",
  width: "100%",
  padding: theme.spacing(2),
  overflowX: "hidden",
}));

const MessageInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  borderTop: "1px solid #ccc",
}));

const MessageInput = styled(TextField)({
  flex: 1,
  background: "#fff",
});

const SendMessageButton = styled(Button)(({ theme }) => ({
  height: "55px",
  width: "100px",
}));

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const adminId = useSelector((state) => state.admin.id);

  useEffect(() => {
    const messagesRef = ref(db, "Messages");

    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesArray = Object.values(data);
        setMessages(messagesArray);
      } else {
        setMessages([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSendMessage = async () => {
    try {
      if (newMessage.trim() !== "") {
        const messagesRef = ref(db, "Messages");
        const newMessageRef = push(messagesRef);
        await set(newMessageRef, {
          message: newMessage,
          Role: "Admin",
          timestamp: Date.now(),
          userId: adminId,
        });

        setNewMessage("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <StyledRoot>
      <ChatContainer>
        {messages.map((message, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "12px 10px",
              alignItems: message.Role === "Admin" ? "end" : "start",
            }}
          >
            <Stack sx={{ width: "max-content", position: "relative", mr: 5 }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "12px",
                  textAlign: message.Role === "Admin" ? "right" : "left",
                }}
              >
                {message.Role}
              </Typography>
              <Typography
                key={index}
                backgroundColor={
                  message.Role === "Admin" ? "#2196f3" : "#4caf50"
                }
                color="#fff"
                borderRadius="8px"
                padding="8px"
                width="max-content"
              >
                {message.message}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "9px",

                  textAlign: "right",
                  textWrap: "noWrap",
                }}
              >
                {moment(message.timestamp).startOf("hour").fromNow()}
              </Typography>
            </Stack>
          </Box>
        ))}
      </ChatContainer>
      <MessageInputContainer>
        <MessageInput
          label="Type your message"
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <SendMessageButton variant="contained" onClick={handleSendMessage}>
          Send
        </SendMessageButton>
      </MessageInputContainer>
    </StyledRoot>
  );
};

export default Chat;

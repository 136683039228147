import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Typography,
    useTheme,
    Tooltip,
  } from "@mui/material";
  import React, { useState, useEffect } from "react";
  import { db } from "../../../Firebase/Firebase";
  import { ref, get } from "firebase/database";
  import { RotatingLines } from "react-loader-spinner";
  import DeleteIcon from "@mui/icons-material/Delete";
  import EditIcon from "@mui/icons-material/Edit";
  import styled from "styled-components";
  const StyledUser = styled(Card)(({ theme }) => ({
    background: "#999",
    border: "1px solid rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }));
  
  const Users = () => {
    const [data, setData] = useState([]);
    const theme = useTheme();
  
    const getUserData = async () => {
      try {
        const userDataRef = ref(db, "users");
        const snapshot = await get(userDataRef);
        if (snapshot.exists()) {
          const values = Object.entries(snapshot.val()).map(([id, user]) => ({ id, ...user }));
          setData(values);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getUserData();
    }, []);
  
    return (
      <Stack sx={{ gap: { xs: 3, md: 5 }, padding: theme.spacing(8) }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "space-between" },
            py: { xs: 3, lg: 2 },
            gap: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ fontSize: { xs: "14px", sm: "15px", md: "18px", lg: "22px" } }}
          >
            Users
          </Typography>
        </Box>
        <Grid container spacing={{  sm: 7, md: 5 }} >
          {data.length > 0 ? (
            data.map((val) => (
              <Grid key={val.id} item xs={12} sm={6} md={6} style={{marginBottom:'5px'}}>
                <StyledUser>
               {/*      <Stack
                    direction="row"
                    sx={{
                      justifyContent: "end",
                      alignItems: "center",
                      width: "100%",
                  
                    }}
                  >
                    <Tooltip title="Edit" placement="top">
                      <Button onClick={() => console.log('Edit user', val.id)}>
                        <EditIcon style={{ color: "#DE3163" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <Button onClick={() => console.log('Delete user', val.id)}>
                        <DeleteIcon style={{ color: "#ff0000" }} />
                      </Button>
                    </Tooltip>
                  </Stack> 
                  */}
                  <CardContent style={{height:'170px'}}>
                  <Box
                  sx={{
                    overflowY: 'auto',  // Ensure vertical scrolling
                    maxHeight: '100px', // Adjust height for mobile screen
                    p: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 1,
                      textAlign: 'center',
                      fontWeight: 'bold',
                      wordBreak: 'break-word', // Ensure long words break correctly
                    }}
                  >
                    {val.id} {/* Repeat content to ensure it's long enough to scroll */}
                  </Typography>
                </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                      }}
                    >
                      Chips: {val.chips}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 1,
                        textAlign: "center",
                      }}
                    >
                      Wins: {val.wins}
                    </Typography>
                  </CardContent>
                </StyledUser>
              </Grid>
            ))
          ) : (
            <RotatingLines
              strokeColor="#6f6e86"
              strokeWidth="5"
              animationDuration="0.75"
              width="160"
              color="#6f6e86"
            />
          )}
        </Grid>
      </Stack>
    );
  };
  
  export default Users;
  
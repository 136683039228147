import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Button,
  Typography,
  DialogActions,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { db, imageDb } from "../../../../Firebase/Firebase";
import { set, ref, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { uid } from "uid";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";
const inititalValues = {
  image: null,
  chips_amount: 0,
  chips_quantity: 0,
  discount_per: 0,
  discounted_amount: 0,
};
const AddPackage = ({ open, close, success }) => {
  const [formValues, setFormValues] = useState(inititalValues);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const storage = getStorage();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    try {
      // Upload the image file to Firebase Storage
      setImageLoading(true);
      const uploadStorageRef = storageRef(
        imageDb,
        `images/${selectedFile.name}-${uid()}`
      );
      await uploadBytes(uploadStorageRef, selectedFile);

      await getDownloadURL(uploadStorageRef)
        .then((url) => {
          setFormValues((previous) => ({
            ...previous,
            image: url,
          }));
          setImageLoading(false);
          console.log("download URL", url);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const pkgDataRef = ref(db, "pkgData");
      const newPkgDataRefRef = push(pkgDataRef);
      const pkgId = newPkgDataRefRef.key;
      const updatedFormValues = { ...formValues, id: pkgId };
      await set(newPkgDataRefRef, updatedFormValues);
      close();
      setFormValues(inititalValues);
      enqueueSnackbar("Data Added Successfully", {
        variant: "success",
      });
      success();
    } catch (error) {
      console.error("Error adding package data:", error);
    }
  };

  // console.log("===formValues=====", formValues);

  useEffect(() => {
    const calculateDiscountedAmount = () => {
      const { chips_amount, discount_per } = formValues;
      const discountedAmount =
        chips_amount - (chips_amount * discount_per) / 100;
      setFormValues((prevValues) => ({
        ...prevValues,
        discounted_amount: discountedAmount,
      }));
    };

    calculateDiscountedAmount();
  }, [
    formValues.chips_amount,
    formValues.chips_quantity,
    formValues.discount_per,
  ]);

  return (
    <div>
      <Dialog open={open} onClose={close} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add New Package</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                {imageLoading ? (
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "230px",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#6f6e86"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="100"
                      color="#6f6e86"
                      visible={imageLoading}
                    />
                  </Stack>
                ) : formValues.image ? (
                  <img
                    src={formValues.image}
                    alt="url"
                    style={{
                      width: "100%",
                      height: "230px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Stack>
                    <Typography variant="h6" fontWeight="bold" mb={1}>
                      Choose Image
                    </Typography>
                    <div
                      style={{
                        textAlign: "center",
                        border: "1px dashed grey",
                        height: "230px",
                        padding: "10px",
                      }}
                    >
                      <input
                        type="file"
                        id="imageInput"
                        name="image"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <label htmlFor="imageInput">
                        <Button variant="contained" component="span">
                          Choose File
                        </Button>
                      </label>
                      <p style={{ marginTop: "10px" }}>
                        {image ? image.name : "No File Chosen"}
                      </p>
                    </div>
                  </Stack>
                )}
              </Grid>

              <Grid item xs={12} lg={12}>
                <TextField
                  label="Amount Chips"
                  placeholder="Enter Chips Amount"
                  fullWidth
                  name="chips_amount"
                  type="number"
                  value={formValues.chips_amount}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Chips Quantity"
                  placeholder="Enter Chips Quantity"
                  fullWidth
                  name="chips_quantity"
                  type="number"
                  value={formValues.chips_quantity}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Discount Percentage"
                  placeholder="Enter Discount percentage"
                  fullWidth
                  name="discount_per"
                  type="number"
                  value={formValues.discount_per}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  label="Discounted Amount "
                  placeholder="Discounted Amount "
                  fullWidth
                  name="discounted_amount"
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={formValues.discounted_amount}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={close}>
              Cancel
            </Button>
            {loading ? (
              <RotatingLines
                strokeColor="#6f6e86"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={loading}
              />
            ) : (
              <Button variant="contained" type="submit">
                Add
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddPackage;

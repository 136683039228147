import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Stack } from "@mui/material";
import { set, ref, push, get, onValue } from "firebase/database";
import { db } from "../../../../../../Firebase/Firebase";
import { useSelector } from "react-redux";
import moment from "moment";

const Messages = ({ data, n_data }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [m_data, set_m_data] = useState([]);
  const user = useSelector((state) => state.admin.id);

  const getClubData = async () => {
    try {
      const clubDataRef = ref(db, "clubData");
      const snapshot = await get(clubDataRef);

      if (snapshot.exists()) {
        const values = Object.values(snapshot.val());
        set_m_data(values.find((val) => val.Name == n_data.Name));
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getClubData();

    const messagesDataRef = ref(db, `clubData/${n_data.Name}/Messages`);

    const unsubscribe = onValue(messagesDataRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesArray = Object.values(data);
        setMessages(messagesArray);
        console.log("messagesArray===", messagesArray);
      } else {
        setMessages([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    if (message.trim() !== "") {
      try {
        const messagesRef = ref(db, `clubData/${n_data.Name}/Messages`);
        const newMessageRef = push(messagesRef);
        const messageData = {
          message: message,
          timestamp: Date.now(),
          userId: user,
          Role: "Admin",
        };
        await set(newMessageRef, messageData);
        setMessage("");
        getClubData();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  return (
    <Box
      height="50vh"
      display="flex"
      flexDirection="column"
      bgcolor="#f0f0f0"
      p={2}
    >
      <Box flex="1" overflow="auto">
        {m_data.Messages === undefined
          ? "No Data"
          : messages.map((msg, index) => {
              const isCurrentUser = msg.userId === user;

              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                    overflowX: "hidden",
                  }}
                >
                  <Stack>
                    <Typography color={isCurrentUser ? "#2979FF" : "#000"}>
                      {msg.Role}
                    </Typography>
                    <Box
                      key={index}
                      bgcolor={isCurrentUser ? "#2979FF" : "#5B5959"}
                      color={isCurrentUser ? "#fff" : "#000"}
                      p={1}
                      borderRadius={4}
                      mb={1}
                      sx={{
                        width: { xs: "150px", sm: "170px", md: "200px" },
                        minWidth: "max-content",
                        textWrap: "wrap",
                      }}
                    >
                      <Typography variant="body1" sx={{ p: 0, m: 0 }}>
                        {msg.message}
                      </Typography>
                    </Box>
                    <Typography
                      color={isCurrentUser ? "#2979FF" : "#000"}
                      textAlign="right"
                      sx={{ mt: -1.4 }}
                    >
                      {moment(message.timestamp).startOf("hour").fromNow()}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
      </Box>
      <Box mt={2} position="sticky" bottom="0" sx={{ display: "flex" }}>
        <TextField
          label="Type your message"
          variant="outlined"
          value={message}
          onChange={handleMessageChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          mt={1}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default Messages;
